var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "product-view", attrs: { flat: "" } },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pt-0 pb-1 px-1" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "px-1 py-1", attrs: { cols: "6" } }, [
                _vm._v(
                  _vm._s(_vm.$t("message.common.orderno")) +
                    ": " +
                    _vm._s(_vm.orderModel.number)
                ),
              ]),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 text-right", attrs: { cols: "6" } },
                [
                  _vm._v(
                    _vm._s(_vm.$t("message.order.delivery")) +
                      ": " +
                      _vm._s(_vm._f("dateFormat")(_vm.orderModel.orderdate))
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pa-0 px-1", attrs: { cols: "12" } }, [
                _vm._v(
                  _vm._s(_vm.$t("message.layout.warehouse")) +
                    ": " +
                    _vm._s(_vm.orderModel.warehouse_name || "")
                ),
              ]),
              _c("v-col", { staticClass: "pa-0 px-1", attrs: { cols: "12" } }, [
                _vm._v(
                  _vm._s(_vm.$t("message.layout.warehouse")) +
                    ": " +
                    _vm._s(_vm.orderModel.warehouse_name_1 || "")
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "highlight pt-0 pb-0 pl-1" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "6" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.products")) + ":")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.ordered")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.received")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.unit")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.listOfProducts, function (product, index) {
            return _c(
              "v-row",
              { key: index },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3",
                    class: { "pb-1": index === _vm.listOfProducts.length - 1 },
                    attrs: { cols: "6" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 pl-3 text-truncate",
                            attrs: { cols: "6" },
                          },
                          [_vm._v(_vm._s(product.productnumber))]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 text-truncate",
                            attrs: { cols: "6", title: product.productname },
                          },
                          [_vm._v(_vm._s(product.productname))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 pl-3 text-right", attrs: { cols: "2" } },
                  [_vm._v(_vm._s(product.orderedqty))]
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 pl-3 text-right", attrs: { cols: "2" } },
                  [_vm._v(" " + _vm._s(product.receivedqty) + " ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 pl-3 text-right", attrs: { cols: "2" } },
                  [
                    _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(product.unitname)),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm.canCollect
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.pickOrderHandler("collect")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.collect")))]
              )
            : _vm._e(),
          _vm.canReceive
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.pickOrderHandler("receive")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.receive")))]
              )
            : _vm._e(),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }